.background-radial-gradient {
    background-color: hsl(0, 0%, 100%);
}

.radius-shape-1 {
    border-radius: 30% 70% 21% 79% / 50% 39% 61% 50%;
    top: 125px;
    right: 100px;
    width: 175px;
    height: 175px;
    background: radial-gradient(#ff6f00, #FFB800);
    overflow: hidden;
    opacity: 0.7;
}
.radius-shape-2 {
    border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
    top: 40%;
    left: 15px;
    width: 210px;
    height: 210px;
    background: radial-gradient(#ff6f00, #FFB800);
    overflow: hidden;
    opacity: 0.4;
}
.radius-shape-3 {
    border-radius: 45% 55% 72% 28% / 55% 36% 64% 45%;
    top: 65%;
    right: 35px;
    width: 235px;
    height: 235px;
    background: radial-gradient(#1D3B46, #AFD1DE);
    overflow: hidden;
    opacity: 0.6;
}

.thumb-text {
    font-family: PlusJakartaSans-Medium, Helvetica, sans-serif;
    font-weight: normal;
}

.thumb-text:hover {
    color: #ff6f00;
}